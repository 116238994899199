<template>
  <div class="systemMsg">
    <div class="top_back">
      <img
        src="../../assets/img/icon/back.png"
        alt=""
        @click.stop="router.back()"
        class="back_icon"
      />
      <div class="msg_name">系统消息</div>
    </div>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <div>
        <van-list
          v-model:loading="loading"
          :finished="finished"
          :finished-text="!loading && finished ? '没有更多了' : ''"
          @load="onLoad"
          class="msg_list"
          :offset="20"
        >
          <div class="msg_item" v-for="item in systemMsgList" :key="item">
            <div class="date">{{ item.time ? imTime(item.time) : "" }}</div>
            <div class="content_box">
              <img class="left_icon" src="@/assets/img/icon/systemnotify.png" />
              <div class="msg_content">
                <img src="../../assets/img/icon/left_s.png" alt="" />
                <div class="msg_text">
                  <span>{{
                    item.payload && item.payload.description ? item.payload.description : "暂无消息"
                  }}</span>
                </div>
              </div>
            </div>
          </div></van-list
        >
      </div>
      <div class="empty" v-if="systemMsgList.length == 0">
        <img src="@/assets/img/home/empty.png" alt="" class="empty_img" />
        <div class="empty_text">暂无数据</div>
      </div>
    </van-pull-refresh>
  </div>
</template>
<script setup name="SystemMsg">
import { chat } from "@/untils/tim";
import { systemMsgIm } from "@/assets/api/im.js";
import { useRouter } from "vue-router";
import { onMounted, ref } from "vue";
const router = useRouter();
const systemMsgList = ref([]); //系统消息
const refreshing = ref(false);
const finished = ref(false);
const loading = ref(true);
const nextReqMessageID = ref("");
function _systemMsgIm() {
  chat()
    .getMessageList({
      conversationID: localStorage.getItem("imChatType") + localStorage.getItem("imChatId"),
      nextReqMessageID: nextReqMessageID.value,
    })
    .then((imResponse) => {
      loading.value = false;
      const messageList = imResponse.data.messageList; // 消息列表。
      finished.value = imResponse.data.isCompleted;
      refreshing.value = false;
      systemMsgList.value = systemMsgList.value.concat(messageList);
      nextReqMessageID.value = imResponse.data.nextReqMessageID; // 用于续拉，分页续拉时需传入该字段。
    })
    .catch(() => {
      loading.value = false;
    });
}
function onRefresh() {
  systemMsgList.value = [];
  refreshing.value = true;
  loading.value = true;
  _systemMsgIm();
}
function onLoad() {
  if (!finished.value) {
    _systemMsgIm();
  }
}
onMounted(() => {
  _systemMsgIm();
});
</script>

<style scoped>
.systemMsg {
  background: #f3f5f6;
  min-height: 100vh;
}
.msg_list {
  padding-top: 10.66vw;
}
.msg_item {
  padding: 0 3.2vw;
}
.date {
  padding: 2.66vw 0;
  text-align: center;
  color: #9ca3af;

  font-family: PingFang SC;
  font-size: 3.46vw;
  font-weight: 400;
}
.content_box {
  display: flex;
}
.left_icon {
  width: 10.66vw;
  height: 10.66vw;
  margin-right: 2.66vw;
}
.msg_content {
  display: flex;
}
.msg_content img {
  width: 6px;
  height: 22px;
  margin-top: 9px;
}

.msg_text {
  background: #fff;
  max-width: 64.5vw;
  border-radius: 4px;
  padding: 2.4vw;
  font-size: 4.26vw;
  font-weight: 400;
  text-align: left;
}

.top_back {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 10.66vw;
  display: flex;
  align-items: center;
  padding: 0 3.73vw;
  background-color: #fff;
  z-index: 99;
}
.back_icon {
  width: 5.33vw;
  height: 5.33vw;
}
.msg_name {
  flex: 1;
  text-align: left;
  color: #000;
  font-family: PingFang SC;
  font-size: 4.26vw;
  font-weight: 500;
  margin-left: 3.73vw;
}
.empty {
  padding: 200px 0 !important;
}
.van-pull-refresh {
  min-height: 100vh;
}
.msg_list {
  min-height: 100vh;
}
</style>
